.body {
  font-family: 'Public Sans', serif;
  font-style: normal;
}
.swal2-backdrop-show {
  z-index: 9999;
}
.swal2-popup .swal2-styled.swal2-confirm {
  background-color: #1890ff !important;
  box-shadow: 0 5px 10px rgba(66, 66, 74, 0.15);
  border-radius: 8px;
  width: 98px;
  height: 40px;
}
.swal2-popup .swal2-styled.swal2-cancel {
  background-color: #ffffff !important;
  color: #f5222d !important;
  border: 1px solid #f5222d !important;
  border-radius: 8px;
  width: 98px;
  height: 40px;
}

.swal2-popup .swal2-styled:focus {
  outline: none !important;
}

.swal2-popup,
  .swal2-actions:not(.swal2-loading),
  .swal2-styled.swal2-confirm:hover {
  background-color: #191919 !important;
}

.swal2-popup,
  .swal2-actions:not(.swal2-loading),
  .swal2-styled.swal2-cancel:hover {
  background-color: #e0e0e0 !important;
}

.custom-input {
  color: #ffffff00 !important;
}

.loading-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .spinner-grow {
    width: 4rem;
    height: 4rem;
  }
}

.MuiFormHelperText-root {
  margin-left: 0 !important;
}

#full-screen {
  display: none;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-size: 50%;
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  background-color: rgba(0, 0, 0, 0.9);
}

#medium-screen {
  display: none;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 50%;
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  background-color: rgba(0, 0, 0, 0.9);
}

.custom-dialog-paper {
  min-width: 600px;

  @media all and (max-width: 768px) {
    min-width: 95%;
  }
}

.custom-multi-carousel {
  padding-bottom: 50px;
  margin-block: 20px;
  display: flex;
  justify-self: center;
  user-select: none;
}

.react-multi-carousel-track {
  padding: revert !important;
}

.editor-wrapper {
  border: 1px solid rgb(240, 240, 240);
  border-radius: 4px;
}

.editor-content {
  padding: 15px !important;
}

.editor-toolbar {
  padding: 15px !important;
  background-color: rgb(245, 245, 245) !important;
}
